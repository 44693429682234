.duel-card {
    --background: linear-gradient(to right, #00c6ff, #0072ff);
    color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    padding-top: 15px;
}

.shifted-div {
    margin-left: -15%;
}

.fighter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.vs {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    display: grid;
    color: white;
    margin: auto;
    height: 100%;
}

.update {
    font-size: 0.8em !important;
    color: #ddd;
    text-align: center;
    margin-top: 10px !important;
}

.fighter-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
}

img.duel-table-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.duel-table-row {
    cursor: pointer;
}

div.won {
    color: rgba(0, 255, 0);
    font-weight: bold;
}

div.lost {
    color: rgba(255, 0, 0);
    font-weight: bold;

}

.duel-table-name .name {
    font-family: 'RF Dewi';
    font-weight: bold;
}

.duel-table-name .level {
    font-family: 'RF Dewi Condensed';
    color: #d4d4d4;
}

.duels-ledger-item {
    width: 80%;
    background: white;
    margin: auto;
    color: black;
    border: 1px solid grey;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* padding-left: 5px; */
    transition: all 0.3s ease;
}

.duels-timestamp {
    color: #C8C9CF;
    font-size: 12px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: -10px;
    margin-left: 40px;
    color: lightgrey
}

.villain-avatar {
    /* border-radius: 50%; */
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    /* max-width: 300px; */
    position: relative;
}

.villain-avatar-rounded {
    border-radius: 50%;
    height: 80%;
    width: 80%;
    object-fit: cover;
    cursor: pointer;
    max-width: 300px;
    position: relative;
}

.hero-avatar-rounded {
    border-radius: 50%;
    /* height: 40%; */
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    max-width: 300px;
    position: relative;
}

.hero-avatar-tour {
    border-radius: 50%;
    height: 70%;
    width: 70%;
    object-fit: cover;
    cursor: pointer;
    max-width: 300px;
    position: relative;
    box-shadow: 2px 7px 9px 0px rgb(154 154 154);
}


.group-image-next {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    object-fit: cover;
    cursor: pointer;
    max-width: 300px;
    position: relative;
}


.grayed-out {
    filter: grayscale(100%);
    opacity: 0.7;
}

.villain-avatar-wrapper {
    position: relative;
    display: inline-block;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    /* centers the text both vertically and horizontally */
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    /* ensures the text doesn't interfere with interactions on the image */
    pointer-events: none;
}

.villan-modal {
    --height: 90% !important;
}

.villian-profile-image {
    text-align: center;
    /* height: 50vh; */
}

.villian-name {
    text-align: center;
}

.villian-profile-description {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.villain-profile-image {}

.villian-profile-name {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    align-items: center;
    display: grid;
}

.villian-profile-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
}

.villian-profile-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.villian-profile-stat-value {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    align-items: center;
    display: grid;
}

.language-bar {
    width: 100%;
    height: 20px;
    background: grey;
    position: relative;
    border-radius: 20px;
}

.label-container {
    width: 100%;
    text-align: center;
    font-size: 12px;
}

.label {
    position: absolute;
    transform: translateX(-50%);
}

.language-level-header {
    font-size: 1em;
    text-align: center;
    align-items: center;
    display: grid;
    margin-bottom: 10px
}

.language-bar-container {
    width: 90%;
    margin: auto;
    margin-top: 21px;
}

.villain-profile-description {
    margin: 20px
}

.read-more {
    color: lightblue;
    margin-top: 5px;
}

.start-duel-footer {
    text-align: center;
}

.start-duel-button {
    font-weight: bold;
    font-size: 20px;
}

.duel-completed-footer {
    background: var(--ion-color-tertiary);
    width: 80%;
    margin: auto;
    text-align: center;
    color: var(--ion-text-color);
}

.countdown-timer {
    display: block;
    background-color: rgb(40, 44, 52);
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    /* height: 55px; */
    margin: 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.countdown-timer-v2 {
    display: block;
    background-color: rgb(100 154 195);
    color: white;
    padding: 5px 0px 5px 10px;
    /* border-radius: 10px; */
    /* width: 200px; */
    /* height: 55px; */
    margin: 10px;
    font-family: 'RF Dewi Condensed';
    text-align: center;
}

.duels-countdown {
    display: flex;
    justify-content: center;
}

.heart-size {
    font-size: 1.5em;
}

.heart-size-desktop {
    font-size: 2.5em;
}

.second-row-health {
    margin-top: -10%;
}

.duel-details-avatar-div {
    width: 50%;
    margin: auto;
}

.duel-details-avatar-image {
    border-radius: 50%;
}

.duel-report-grid {
    text-align: center;
    font-size: 1.5em;
    display: grid;
    align-items: center;
}

.villain-level-div {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}

.villain-hours-div {
    text-align: center;
    font-size: 20px;
    font-style: italic;
}

.report-level {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    align-items: center;
    display: grid;
}

.duel-report-odd-row {
    background: var(--ion-color-secondary);
    display: flex;
    align-items: center;
    height: 80px;
}

.duel-report-even-row {
    background: var(--ion-color-tertiary);
    display: flex;
    align-items: center;
    height: 80px;
}

.duel-report-outer-column {}

.duel-report-inner-column {
    font-size: 60%;
    font-style: italic;
}

.duel-report-vs-column {
    font-size: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.duel-report-luckFactor {
    padding: 4%;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.duel-record-columns {
    margin-top: 10px;
    margin-bottom: 10px;
}

.duel-record-label {
    font-family: 'RF Dewi Condensed';
    color: darkgray;
}

.duel-record-stat {
    font-weight: bold;
    font-size: 2.5vh;
    text-shadow: 1px 1px 0px #666666;
}

.duel-tally-stat {
    font-weight: bold;
    font-size: 20px;
    text-shadow: 1px 1px 0px #666666;
}

.duel-table-name {
    margin: auto
}

.duel-table-result {
    margin: auto
}

.duel-table-result .date {
    font-size: 12px;
    /* margin-top: 10px; */
    font-family: 'RF Dewi Condensed';
    color: #d4d4d4;
}

.duel-table-score {
    margin: auto;
    text-align: center;
}

.game-map {
    width: 90%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.game-map::-webkit-scrollbar {
    display: none;
}

.specialty-villain-groups {
    display: inline-flex;
}

.specialty-villain-groups-larger-container {
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 90%;
    margin: auto;
}

.specialty-villain-groups-container {
    overflow-x: auto;
    overflow-y: hidden;
    height: 135px;
}

.specialty-villain-groups-container::-webkit-scrollbar {
    display: none;
}

.villain-group-map {
    position: relative;
    /* Essential to position children with absolute positioning */
    width: 100%;
    /* Take up full width of the container */
    height: 100%;
    /* Example height - adjust as needed */
    background: rgba(255, 255, 255, 0.05);
    /* Slight grey on a dark background to see the game map area */
    margin: auto;
    /* Some spacing around the map for clarity */
    border-radius: 10px;
    /* Optional, but gives a nice rounded corner effect */
    background-size: cover;
    /* overflow: auto; */
}

.villain-group-map::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0);
    opacity: 0.7;
    z-index: 0;
}

.villian-group-name-title {
    color: white;
    position: relative;
    text-align: center;
}

.villain-node {
    position: absolute;
    /* So you can place it based on x, y coordinates */
    /* width: 100px;
    height: 100px; */
    background: #353535;
    border-radius: 50%;
    /* Makes it a circle */
    cursor: pointer;
    /* Indicates interactivity */
    transition: 0.3s;
    /* Smoothens any state changes */
    display: flex;
    /* Center content inside the node */
    align-items: center;
    /* Center content vertically */
    justify-content: center;
    /* Center content horizontally */
    z-index: 1000;
}

.completed {
    opacity: 0.1;
    /* background-color: green; */
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; */
}

.path {
    position: absolute;
    /* Essential for placing based on start position and to give it a rotation */
    height: 5px;
    /* Thickness of the path */
    background: blue;
    /* Path color */
    transform-origin: 0% 50%;
    /* Ensure it rotates around the start */
}

.villain-image-node {
    object-fit: cover;
    border-radius: 50%;
    width: 90px;
    height: 90px;
}

.logo-image-node {
    width: 50px;
    height: 50px;
}

.villainGroup-image-node {
    object-fit: cover;
    border-radius: 20px;
    width: 200px;
    height: 200px;
}

.specialty-villainGroup-image-node {
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    height: 100px;
    display: block;
}

.specialty-group-name {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 0px 0px 20px 20px;
    bottom: 0;
}

.specialty-villain-group {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    position: relative;
    display: inline-block;
}

.specialty-group-reason {
    text-align: center;
    font-size: 10px;
    color: white;
    margin-top: 10px;
}

.last-node {
    opacity: 0.4;
}

.game-map-inner {
    width: 320px;
    margin: auto;
    position: relative;
    height: 90%;
    overflow-y: auto;

    /* max-width: 400px; */
    &::-webkit-scrollbar {
        display: none;
    }
}

.group-node {
    position: relative;
    /* So you can place it based on x, y coordinates */
    /* width: 100px;
    height: 100px; */
    background: #353535;
    border-radius: 50%;
    /* Makes it a circle */
    cursor: pointer;
    /* Indicates interactivity */
    transition: 0.3s;
    /* Smoothens any state changes */
    display: flex;
    /* Center content inside the node */
    align-items: center;
    /* Center content vertically */
    justify-content: center;
    /* Center content horizontally */
    z-index: 1000;
    /* transform: translate(-50%); */
    border-radius: 20px;
}

.specialty-villain-groups-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
}

.group-node-celebration {
    position: relative;
    background: #353535;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.group-node-current {
    position: relative;
    /* So you can place it based on x, y coordinates */
    /* width: 100px;
    height: 100px; */
    background: #353535;
    border-radius: 50%;
    /* Makes it a circle */
    cursor: pointer;
    /* Indicates interactivity */
    transition: 0.3s;
    /* Smoothens any state changes */
    display: flex;
    /* Center content inside the node */
    align-items: center;
    /* Center content vertically */
    justify-content: center;
    /* Center content horizontally */
    z-index: 1000;
    width: 200px;
    height: 200px;
}

.group-node-locked {
    position: absolute;
    background-color: #505050;
    height: 100%;
    width: 100%;
    /* border-radius: 50%; */
    color: white;
    font-size: 30px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border-radius: 20px;
}

.group-node-locked-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    color: #eeeeeed6;
    font-size: 60px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-node-completed {
    position: absolute;
    height: 100%;
    width: 70%;
    /* border-radius: 50%; */
    color: #ffffff;
    font-size: 14px;
    z-index: 100;
    display: flex;
    align-items: end;
    text-align: center;
    margin-bottom: 65%;
}

.specialty-group-node-completed {
    position: absolute;
    height: 100%;
    color: #ffffff;
    font-size: 12px;
    z-index: 100;
    display: flex;
    text-align: center;
    align-content: center;
    flex-wrap: wrap;
}

.group-name {
    position: absolute;
    top: 70%;
    width: 100%;
    color: white;
    font-size: 14px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 0px 0px 20px 20px;
    min-height: 60px;
}

.villian-group-line-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    /* This ensures the SVG container stretches to cover the entire height of the parent container */
    width: 100%;
    /* This ensures the SVG container stretches to cover the entire width of the parent container */
    z-index: 1;
    /* This can be adjusted to ensure the lines appear above or below certain elements */
}

.node-connector {
    position: absolute;
    width: 2px;
    /* Adjust the thickness of the line */
    background: none;
    border-left: 2px dotted blue;
    /* Creates a dotted line */
    z-index: 1;
    /* Ensure it's behind the nodes if needed */
}

.duel-current-duel-div {
    position: absolute;
    color: white;
    font-size: 30px;
}

.x-image-group {
    position: absolute;
    width: 75px;
}

.villains-in-group {}

.villain-difficulty-modal {
    --height: fit-content !important;
    --width: 80% !important;
    --backdrop-opacity: .7 !important
}

.villain-difficulty-modal-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    text-align: center;
}

.villian-difficulty-box {
    width: 65%;
    color: white;
    padding: 15px;
    margin: auto;
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}

.duel-instuction-header {
    text-align: center;
    background: var(--mobility-color);
    color: white;
}

.duel-instructions-emphasis {
    font-weight: bold;
    color: orange
}

.ledger-commentary-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0px;
    /* Space out the elements */
    margin-block-end: 0px;
    margin-block-start: 0px;

}

.ledger-commentary-background {
    background: #75aad2;
    color: white;
    padding-bottom: 4px;
    margin-bottom: 10px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.duel-status-info-stats-column {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.heart-animation {
    animation: pulse 1s 5 forwards;
    /* 5 cycles, retain style of last keyframe */
    /* font-size: larger; */
    /* Adjust size as needed */
    color: #fff;
    /* Original color */
}

@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
        color: #fff;
        /* Original color and scale at start and end */
    }

    50% {
        transform: scale(1.2);
        color: red;
        /* Dramatic effect in the middle of the animation */
    }
}

.health-animation span {
    transition: font-size 0.5s ease-in-out, color 0.5s ease-in-out;
    font-size: initial;
    color: initial;
}

/* During the animation, change size and color */
.duel-status-info-stats-column .health-animation span {
    /* font-size: larger; */
    /* Adjust size as needed */
    color: #fff;
    /* Change color for dramatic effect */
}

.hero-odds-div {
    font-size: 14px;
    border-radius: 6px;
    padding: 7px;
    border: 4px solid;
}

.up-arrow-weakness {
    fill: rgb(0, 255, 0);
    font-size: 30px;
}

.down-arrow-weakness {
    fill: rgb(255, 0, 0);
    font-size: 30px;
}

.weakness-header {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.weakness-container {
    width: 90%;
    margin: auto auto 40px auto;
    padding: 10px;
    border: 1px solid;
}

.select-difficulty {
    display: grid;
    align-items: center;
    width: 70%;
    margin: 10px auto;
    font-weight: 100;
    /* border-width: 1px;
    border-color: white;
    border-style: solid; */
    justify-content: center;
}

.villain-boxes {
    width: 48%;
    color: white;
    padding: 15px;
}

.duels-segment-button {
    font-size: 12px;
}

.duels-segment-button-icon {
    font-size: 20px;
    padding-bottom: 2px;
}

.duels-segment-icon-special {
    font-size: 24px;
    margin-bottom: -3px;
    /* margin-top: -7px; */
}

.challenge-segment-icon-special {
    font-size: 24px;
}

.completion-circle {
    width: 20px;
    height: 20px;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    background-color: black;
}

.completion-circle.easy {
    border: 1px solid green;

}

.completion-circle.easy.completed {
    background-color: green;
    opacity: 1.0;
}

.completion-circle.medium {
    border: 1px solid blue;
}

.completion-circle.medium.completed {
    background-color: blue;
    opacity: 1.0;
}

.completion-circle.hard {
    border: 1px solid orange;
}

.completion-circle.hard.completed {
    background-color: orange;
    opacity: 1.0;
    color: black;
}

.completion-circle.boss {
    border: 1px solid red;
    width: 50%;
}

.completion-circle.boss.completed {
    background-color: red;
    opacity: 1.0;
    color: white;
}

.tally-mark-column {
    display: flex;
    justify-content: center;
}

.tally-placement {
    position: relative;
    top: -20px;
}

.upcoming-activities {
    width: 90%;
    margin: auto;
    background: var(--ion-color-secondary);
    margin-bottom: 10px;
}

.upcoming-activities-header {
    margin: 15px 0px 15px 10px;
    color: white;
}

.skill-end-time {
    /* font-weight: bold; */
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    color: white;
}

.skill-in-use-icon {
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
}

.duels-segment {
    position: fixed;
    z-index: 20000;
    width: 95%;
    background: #434343;
    /* border-radius: 50px;
    border: 1px gray solid; */
}

.duels-progress-segment-button {
    /* border-left: 1px solid;
    border-right: 1px solid;
    border-color: gray;
    border-radius: 0px; */
    --border-color: blue;
    --border-style: solid;
    --border-width: 1px;
}

ion-segment-button::part(indicator-background) {
    background: var(--ion-color-tertiary);
}

.duels-progress-segment-label {
    margin-left: 5px;
}

.duel-mini-header-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    color: white;
}

.duel-mini-header-names {
    text-align: center;
    margin: auto;
}

.duel-skill-selection-box {
    border: 1px solid white;
    margin: auto;
    height: 60px;
}

.duel-skill-selection-box-text {
    font-size: 12px;
    font-weight: bold;
    margin: auto;
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #303030;
}

.duel-skill-select-div {
    margin: auto;
    width: 90%;
    padding-bottom: 20px;
}

.duel-skill-select-header {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}