.swiper-button-next,
.swiper-button-prev {
    visibility: hidden;
}

.add-activity-icon {
    font-size: 22px;
    padding-right: 15px;
}

.add-activity-icon-large {
    font-size: 100px;
}

.section-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.add-activity-large {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-screen-data {
    width: 100%;
    text-align: right;
}

ion-modal#set-date-modal {
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 80%;
}