.keyboard-is-open {
    ion-content.md {
        --offset-bottom: calc(var(--keyboard-height) + 50px) !important;
    }
}

.most-fun {
    font-style: italic;
    font-size: 12px;
    color: orange;
}

.cardio-text {
    background-color: var(--cardio-color);
    color: black;
}

.strength-text {
    background-color: var(--strength-color);
    color: white;
}

.mobility-text {
    background-color: var(--mobility-color);
    color: black;
}

.avatar-option-select {
    background-color: black;
}

.avatar-creation-label-style {
    font-weight: bold;
    font-size: 1.2em;
    color: #fff;
    margin: 0.5em 0;
}

.photo-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5em 0;
}

.avatar-creation-select-style {
    margin: 0.5em 0;
    background-color: #75aad2;
    color: #fff;
    font-weight: bold;
    padding: 0px 10px 0px 10px;
    border-radius: 4px;
}

.select-selected {
    background-color: rgb(0, 189, 0);
}

.onboarding-text-area {
    font-size: 18px;
    margin-right: 20px;
    margin-left: 20px;
    text-align: left;
    margin-top: 5%;
}

.onboarding-emphasis {
    font-size: 18px;
    font-weight: bold;
    color: orange;
}

.onboarding-title {
    font-size: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    width: 100%;
    margin: auto;
    font-family: 'RF Dewi Condensed Bold';
    padding-left: 20px;
    padding-right: 20px;
}

.onboarding-subtitle {
    font-size: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
    margin: auto;
    font-family: 'RF Dewi Condensed Bold';
}

.onboarding-subtext {
    font-size: 17px;
    font-family: 'RF Dewi Condensed';
    font-style: italic;
}

.onboarding-main-div {
    /* margin-left: 20px;
	margin-right: 20px; */
    height: 90%;
    display: grid;
}

.onboarding-main-text {
    font-family: 'RF Dewi Condensed';
    font-size: 16px;
    margin-left: 20px;
}

.onboarding-footer {
    margin-bottom: 10%;
    margin-top: 16px;
    text-align: center;
}

.onboarding-advance-button {
    min-width: 50%;
    width: 80%;
    color: white;
}

div.onboarding-hero-image {
    /* height: 60%;
	width: 100%; */
    height: 50vh;
}

img.onboarding-hero-image {
    width: 100%;
    height: 100%;
    object-fit: none;
    object-position: top;
}

img.onboarding-activity-image {
    width: 100%;
    object-fit: cover;
    object-position: top;
}

div.onboarding-activity-title {
    text-align: center;
    font-family: 'RF Dewi Condensed Bold';
}

.onboarding-intro-video-play-button-div {
    position: absolute;
    top: 200px;
    left: 40%;
}

.onboarding-add-photo-button {
    height: 50%;
    width: 50%;
    object-fit: contain;
    object-position: top;
    cursor: pointer;
}

.onboarding-avatar-image {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    object-fit: cover;
    object-position: center;
}

.onboarding-add-photo-button-div {
    position: relative;
    text-align: center;
    margin: auto;
    width: 30%;
    font-size: 2vh;
}

.onboarding-form {
    background-color: var(--ion-color-light);
}

.onboarding-disclaimer {
    padding: 16px;
    color: var(--ion-color-primary);
    text-align: center;
    opacity: 0.4;
    font-size: 10px;
}

.onboaring-preview-photo-div {}

.onboaring-preview-photo {
    width: 100px;
    height: 100px;
    border-radius: 70px;
    object-fit: cover;
}

.onboarding-error-message {
    color: red;
    font-style: italic;
    font-size: 0.75em;
    text-align: center;
}

.onboarding-traidng-card-background {
    width: 80%;
}

.onboarding-trading-card-div {
    text-align: center;
}

.onboarding-trading-card-avatar {
    height: 100%;
    object-fit: cover;
}

.onboarding-trading-card-avatar-div {
    position: absolute;
    left: 16%;
    top: 14%;
    width: 56%;
    height: 72%;
}

.onboarding-hero-image-div {
    text-align: center;
    /* padding-left: 16px;
	padding-right: 16px; */
    height: 25vh;
    margin: 20px;
}

.onboarding-help-text {
    font-size: 12px;
    font-style: italic;
}

.onboarding-app-button {
    min-width: 80%;
}

.onboarding-app-button-div {
    width: 100%;
    padding-top: 20px;
}

.onboarding-hk-datapoint {
    font-size: 19px;
    padding-top: 10px;
    font-weight: bold;
}

.onboarding-field-label {
    font-weight: bold;
    font-size: 22px !important;
    padding-top: 13px !important;
}

.onboarding-radio-item {
    border-style: solid;
    border-radius: 2px;
    border-width: 1px;
    border-color: var(--ion-color-primary);
    margin-top: 10px;
}

.onboarding-radio-label {
    display: flex;
    height: 25px;
    padding-left: 20px;
    padding-top: 1px;
}

.onboarding-radio-label-question {
    padding-left: 20px;
    padding-top: 1px;
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: unset !important;
}

.onboarding-question-thanks {
    text-align: center;
    font-size: 1.5em;
    margin-top: 10%;
}

.onboarding-image-button {
    height: 100%;
    --padding-start: 0px;
    --padding-end: 0px;
}

.onboarding-username-box {
    width: 90%;
    margin: auto;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: var(--ion-color-tertiary);
    height: 42px;
    font-size: 18px;
    --padding-start: 10px;
    text-align: center;
    color: white;
}

.chat-bubble-onboarding {
    position: relative;
    width: 90%;
    padding: 35px;
    font-size: 20px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    color: black
}

.chat-bubble-onboarding::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 25%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 30px 10px;
    border-color: transparent white transparent transparent;
}


.onboarding-firstname-box-div {
    text-align: center;
    font-size: 24px;
    margin-top: 50px;
}

.squad-code-box {
    height: 40px;
    margin-top: 10px !important;
    width: 50%;
    font-size: 14px;
    margin: auto;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
    border-color: lightgray;
    text-align: center;
}

.onboarding-email-box {
    width: 90%;
    margin: auto;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: var(--ion-color-tertiary);
    height: 42px;
    font-size: 18px;
    --padding-start: 10px;
}

.onboarding-username-box-div {
    /* margin-top: 10%; */
    /* margin-bottom: 10%; */
}

.initial-goal-table-column {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ion-color-primary);
}

.sumRow {
    padding-top: 15px;
    border-top-width: 17px;
    border-bottom-style: double !important;
    border-bottom-width: 5px;
    border-top-width: 1px;
    border-top-style: solid;
}

.active {
    border: 10px solid aqua
}

.motivate-option {
    width: 70%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 20px;
}

.motivate-option-active {
    background: orange;
    color: white;
}

.finding-challenge-progress-bar {
    height: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
}


.onboarding-progress-bar {
    --progress-background: var(--ion-color-logo-green)
}

.onboarding-upload-photo {
    text-align: center;
    cursor: pointer;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

ion-select::part(icon) {
    color: black;
    opacity: 1;
}

ion-select::part(text) {
    text-align: center;
}

ion-select {
    --placeholder-color: white;
    --placeholder-opacity: 1;
    width: 100%;
    justify-content: center;
}

.famous-athlete-image {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

/** CUSTOM CHECKBOXES **/
.checkbox-wrapper-57 input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

.checkbox-wrapper-57 *,
.checkbox-wrapper-57 ::after,
.checkbox-wrapper-57 ::before {
    box-sizing: border-box;
}

.checkbox-wrapper-57 .container {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 25px;
    user-select: none;
}

/* Create a custom checkbox */
.checkbox-wrapper-57 .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background: lightblue;
    border-radius: 50px;
    transition: all 0.7s;
    --spread: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper-57 .container input:checked~.checkmark.fire {
    background: black;
    box-shadow: -10px -10px var(--spread) 0px #f06000, 0 -10px var(--spread) 0px #800000, 10px -10px var(--spread) 0px #E1306C, 10px 0 var(--spread) 0px #FD1D1D, 10px 10px var(--spread) 0px #F77737, 0 10px var(--spread) 0px #FCAF45, -10px 10px var(--spread) 0px #FFDC80;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.steel {
    background: black;
    box-shadow: -10px -10px var(--spread) 0px #1100ff, 0 -10px var(--spread) 0px #383838, 10px -10px var(--spread) 0px #00c3ff, 10px 0 var(--spread) 0px #222222, 10px 10px var(--spread) 0px #197dff, 0 10px var(--spread) 0px #2e2e2e, -10px 10px var(--spread) 0px #000000;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.magic {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #5D3FD3,
        0 -10px var(--spread) 0px #7F00FF,
        10px -10px var(--spread) 0px #D291BC,
        10px 0 var(--spread) 0px #4A00E0,
        10px 10px var(--spread) 0px #8E2DE2,
        0 10px var(--spread) 0px #AA00FF,
        -10px 10px var(--spread) 0px #B3A1E6;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.fantasy {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #556B2F,
        /* Dark Olive Green */
        0 -10px var(--spread) 0px #6B8E23,
        /* Olive Drab */
        10px -10px var(--spread) 0px #8FBC8F,
        /* Dark Sea Green */
        10px 0 var(--spread) 0px #228B22,
        /* Forest Green */
        10px 10px var(--spread) 0px #A0522D,
        /* Sienna */
        0 10px var(--spread) 0px #8B4513,
        /* Saddle Brown */
        -10px 10px var(--spread) 0px #DEB887;
    /* Burlywood */
}

.checkbox-wrapper-57 .container input:checked~.checkmark.scifi {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #00FFFF,
        0 -10px var(--spread) 0px #0FF0FF,
        10px -10px var(--spread) 0px #8A2BE2,
        10px 0 var(--spread) 0px #7FFF00,
        10px 10px var(--spread) 0px #FF1493,
        0 10px var(--spread) 0px #00BFFF,
        -10px 10px var(--spread) 0px #1E90FF;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.cyborg {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #A9A9A9,
        0 -10px var(--spread) 0px #808080,
        10px -10px var(--spread) 0px #696969,
        10px 0 var(--spread) 0px #778899,
        10px 10px var(--spread) 0px #708090,
        0 10px var(--spread) 0px #2F4F4F,
        -10px 10px var(--spread) 0px #1C1C1C;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.pirate {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #8B4513,
        0 -10px var(--spread) 0px #A0522D,
        10px -10px var(--spread) 0px #D2691E,
        10px 0 var(--spread) 0px #DEB887,
        10px 10px var(--spread) 0px #F4A460,
        0 10px var(--spread) 0px #D2B48C,
        -10px 10px var(--spread) 0px #BC8F8F;
}

.checkbox-wrapper-57 .container input:checked~.checkmark.egyptian {
    background: black;
    box-shadow:
        -10px -10px var(--spread) 0px #C0C0C0,
        0 -10px var(--spread) 0px #FFD700,
        10px -10px var(--spread) 0px #B8860B,
        10px 0 var(--spread) 0px #FFA07A,
        10px 10px var(--spread) 0px #CD853F,
        0 10px var(--spread) 0px #DAA520,
        -10px 10px var(--spread) 0px #F0E68C;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-wrapper-57 .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper-57 .container input:checked~.checkmark::after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper-57 .container .checkmark::after {
    left: 0.5em;
    top: 0.34em;
    width: 0.25em;
    height: 0.5em;
    border: solid #f0f0f0;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.avatar-creation {
    width: 95%;
    margin: auto;
}