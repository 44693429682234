.assistant-message-container {
    text-align: left;
    color: white;
    width: 70%;
    margin-right: auto;
    padding-top: 20px;
}

.assistant-message-bubble {
    display: inline-block;
    padding: 10px;
    border-radius: 15px;
    background-color: #333337;
}

.bubble-pad {
    margin-top: 10px;
}

.assistant-message-div {
    display: flex;
}

.duels-show-all-messages-button {
    width: 50%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    background: var(--ion-color-success);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
}

.hero-message-div {
    display: flex;
}

.assistant-message-avatar {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-left: 7px;
    margin-right: 7px;
}

.hero-message-avatar {
    height: 40px;
    width: 40px;
    margin-top: 20px;
    margin-left: 7px;
    margin-right: 7px;
}

.assistant-message-container-typing {
    text-align: left;
    color: white;
    width: 70%;
    margin-right: auto;
    padding-top: 20px;
}

.hero-message-container-typing {
    text-align: right;
    color: white;
    width: 70%;
    margin-left: auto;
    padding-top: 20px;
}

.user-message-container {
    text-align: right;
    color: black;
    width: 70%;
    margin-left: auto;
    padding-top: 20px;
    margin-right: 7px;
}

.hero-message-container {
    text-align: right;
    color: black;
    width: 70%;
    margin-left: auto;
    padding-top: 20px;
    margin-right: 7px;
}

.chat-reply-options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 10px auto 10px auto;
}

.chat-reply-button {
    --ion-color-contrast: white !important;
    height: 25px;
    margin: 10px;
    font-size: 16px;
}

.user-message-bubble {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: white
}

.hero-message-bubble {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: white
}

.message-timestamp {
    color: #C8C9CF;
    font-size: 12px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 3px;
}

.message-timestamp.bot {
    margin-left: 65px;
}

.message-timestamp.user {
    margin-right: 7px;
}

.message-timestamp.hero {
    padding-right: 65px;
    width: 100%;
    text-align: right;
}