.profile-container {
    background-color: #1a1a1a;
    /* Dark background */
    color: #ffffff;
    /* White text color */
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #333;
    /* Slightly lighter line for contrast */
    position: relative;
}

.winrate {
    position: absolute;
    left: 15px;
    /* padding from the left */
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    color: #00aaff;
    /* Bright blue color */
}

.avatar {
    order: 2;
    /* this will ensure the avatar is in the middle */
    width: 90px;
    height: 90px;
    margin-right: 20px;
}

.last-played,
.record {
    font-size: 14px;
    margin: 2px 0;
    color: #aaaaaa;
    /* Slightly less bright gray */
}

.record {
    margin-right: 15px;
    /* padding from the right */
}

.matches-list {
    margin-top: 20px;
    border-top: 1px solid #333;
    /* Slightly lighter line for contrast */
}

.hero-name {
    font-weight: bold;
    font-size: 16px;
}

.match-result,
.match-stats,
.match-time {
    font-size: 14px;
    color: #888;
    /* Gray text */
}

.match-time {
    color: #aaaaaa;
    /* Slightly less bright gray */
}

.match-hero-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* Make it circular */
    margin-right: 15px;
    /* Space between image and the label */
}