.background {
    --background: #14151C;
}

.toolbar-right-buttons {
    position: absolute;
    right: 0;
}

.toolbar-left-buttons {
    position: absolute;
    left: 0;
}

.toolbar-avatar-holder {
    color: white;
    text-align: center;
}

.app-main {
    margin: auto;
}

.button-as-link {
    --background: none;
    color: var(--ion-color-primary);
    --background-activated: none;
    --background-focused: none;
    --background-hover: none;
    --box-shadow: none;
}

.heroSpeaksTooltipClass {
    background-color: #14151c00 !important;
    color: white;
    border-radius: 20px !important;
    box-shadow: none !important;
}

.heroSpeaksTooltipClass .introjs-tooltip-header {
    padding-top: 0px !important;
    min-height: none !important;
}

.heroSpeaksTooltipClass .introjs-tooltip-title {
    min-height: none !important;
}

.heroSpeaksTooltipClass .introjs-tooltipbuttons {
    border-top: none !important;
    margin-top: -53px !important;
}

.heroSpeaksTooltipClass .introjs-prevbutton {
    visibility: hidden !important;
}

.heroSpeaksTooltipClass .introjs-tooltiptext {
    padding: 0px !important;
}

.heroSpeaksHighlightClassFinal .introjs-helperLayer {
    z-index: 0 !important;
}



@keyframes tourHighlightPulse {
    0% {
        background-color: rgba(82, 160, 253, 0);
    }

    50% {
        background-color: rgba(82, 160, 253, 0.5);
    }

    100% {
        background-color: rgba(82, 160, 253, 0);
    }
}

.heroSpeaksHighlightClass {
    border-color: white !important;
    border: 1px solid;
    position: relative;
    z-index: 0 !important;
    /* box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px !important; */
}

.heroSpeaksHighlightClassFinal {
    border-color: white !important;
    border: 1px solid;
    animation: tourHighlightPulse 1.5s infinite;
    position: relative;
    z-index: 0 !important;
    /* box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px !important; */
}

.highlightClassFinal::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    /* Inherit the pulsing background color */
    z-index: -1;
}

.myTooltipClass {
    background-color: var(--ion-color-primary) !important;
    color: white;
    border-radius: 20px !important;

}

.myTooltipClass .introjs-button {
    background: none !important;
    color: white;
    text-shadow: none !important;
    border: 0px !important;
}

.myTooltipClass .introjs-button:active {
    background: none !important;
    color: white;
    text-shadow: none !important;
    border: 0px !important;
}

.myTooltipClass .introjs-button:focus {
    background: none !important;
    color: white;
    text-shadow: none !important;
    border: 0px !important;
}

.myTooltipClass .introjs-skipbutton {
    color: white;
    font-size: 34px;
    border: 0px !important;
}

.myTooltipClass .introjs-tooltipbuttons {
    border: none !important;
    padding: 0 !important;
    border: 0px !important;
}


.strengthTooltipClass {
    background-color: rgba(255, 0, 0) !important;
    color: white;
}

.cardioTooltipClass {
    background-color: rgba(0, 255, 0) !important;
    color: white;
}

ion-select::part(icon) {
    color: var(--ion-text-color) !important;
    opacity: 1;
}

ion-modal#set-password-modal {
    --min-width: 250px;
    --height: 250px;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 80%;
}

.new-sidekick-message {
    position: absolute;
    left: 60px;
    top: 0px;
    animation: pulse 1.0s infinite;
    font-size: larger;
}

.new-hero-message-mobile {
    position: absolute;
    left: 45px;
    top: 0px;
    /* animation: pulse 5.0s infinite; */
    font-size: 10px;
    background: red;
    border-radius: 50px;
    width: fit-content;
    height: fit-content;
    color: white;
    padding: 2px;
}

.new-hero-message-desktop {
    position: absolute;
    left: 70px;
    top: 0px;
    animation: pulse 1.0s infinite;
    font-size: medium;
}

@keyframes pulse {
    0% {
        transform: scale(0.5);
    }

    50% {
        transform: scale(1.1);
    }

    /* Increase scale for dramatic effect */
    100% {
        transform: scale(0.5);
    }
}

.emphasis-text {
    font-size: 18px;
    font-weight: bold;
    color: orange;
}

.hero-image-alert .alert-message {
    max-height: 100%;
}

.share-component-div {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}