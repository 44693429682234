.chat-footer {
	bottom: 0;
	position: absolute;
	--background: #fff;
}

.free-text {
	width: 100%;
}

.chat-footer-button {
	max-width: 100% !important;
	overflow: initial;
	white-space: initial;
}

.chat-input-row {
    width: 90%;
    margin: auto;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.chat-input-textarea {
	--padding-start: 10px;
	--padding-end: 10px;
	font-size: 16px;
	margin-top: 0px !important;
	background: #2B2B30;
    border-radius: 15px;
    border: 1px #484848 solid;
}

.submit-button {
	height: 100%;
	margin: 0px !important;
	color: var(--ion-color-primary);
	--box-shadow: none;
}

.gif-button {
	--padding-start: 0px !important;
	--padding-end: 0px !important;
}

.send-button {
	height: 100%;
	margin: 0px !important;
	color: var(--ion-color-dark) !important;
	--box-shadow: none;
	position: relative;
	left: -13px;
	z-index: 100;
	width: 39px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.input-col {
	width: 80%;
}

.button-col {
	width: 20%;
}

.chat-input-toolbar {
	
}

.chat-input-textarea {
	color:white;
}

