ion-modal#select-specialization-modal {
    /* --width: fit-content; */
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 90%;
}

ion-modal#use-skill-modal {
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 80%;
    --backdrop-opacity: .7 !important
}

.skill-header {
    text-align: center;
    margin-bottom: 10px;
    background-color: purple;
    color: white;
    width: 40%;
    margin: 0px auto 10px auto;
    border-radius: 20px;
    padding: 5px;
    font-variant: small-caps;
}

.skill-row {
    background-color: rgba(0, 0, 0, 0.5);
    /* border: 2px solid rgb(255, 255, 255); */
    margin-left: 10px;
    width: 100%;
    display: flex;
    overflow-x: auto;
    width: 95%;
}

.skill-row-v2 {
    margin: auto;
    display: flex;
    overflow-x: auto;
    width: 85%;
    flex-wrap: wrap;
    flex-direction: row;
}

.skill-group-header {
    color: white;
    width: 100%;
    font-size: 20px;
    margin: 10px 0px 0px 10px;
    padding: 5px;
    border-radius: 20px;
}

.skill-row::-webkit-scrollbar {
    display: none;
}

.skill-column-level {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skill-column-name {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
}

.skill-column-specialization-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    /* margin-right: 11px; */
    width: 27%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.skill-column-icon {
    display: flex;
    align-items: center;
}

.skill-slection-column-icon {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}


.skill-selection-description-col {
    padding: 0px;
}

.skill-selection-description {
    font-size: 12px;
}

.skill-icon {
    /* margin-top: 10px; */
    margin-bottom: 5px;
    /* height: 48px;
    width: 48px; */
    max-width: 70px;
}

.skill-selected-overlay {
    position: relative;
}

.skill-selected-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Adjust the color and opacity as needed */
    z-index: 2;
    /* Ensure this is higher than the contents of the row */
}

.skill-specialization-icon {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 38px;
    width: 38px;
}

.skill-owned {
    border: 2px solid yellow;
    /* padding: 10px; */
    margin: 10px;
    text-align: center;
    box-shadow: inset 0 0 20px 3px yellow;
    transition: all 0.3s ease-in-out;
    width: 95%
}

.skill-specialization-div {
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.skill-tree-title {
    color: white;
    position: relative;
    text-align: center;
    margin: 20px;
    font-size: 30px;
}

.skill-overlay {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    position: fixed;
}

.skill-wrong-specialization {
    /* color: #505050;
    border: 0px; */
    opacity: 0.4;
}

.skills-specialization-segment-button-owned {
    border: 2px solid yellow;
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: inset 0 0 20px 3px yellow;
    transition: all 0.3s ease-in-out;
}

.skills-specialization-segment-button {
    padding: 10px;
    margin: 10px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.skill-card-image-div {
    height: 200px;
    display: flex;
    justify-content: center;
}

.skill-icon-container-outer {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.skill-icon-container-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
    margin: 0px 10px 10px 0px;
}

.skill-icon-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    /* Slightly opaque black */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: large;
}

.skills-cant-use {
    text-align: center;
    margin: 20px;
    background: red;
    color: white;
    padding: 20px;
}

.skill-name {
    font-style: bold;
    font-variant: small-caps;
    font-size: 24px;
}

.skill-icon-celebration {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    max-width: 300px;
    position: relative;
}

.cooldown-svg {
    width: 100%;
    /* SVG will take the full width of the .skill-icon-overlay */
    height: 100%;
    /* SVG will take the full height of the .skill-icon-overlay */
}

.cooldown-text {
    font-size: 10px;
    /* Adjust the font-size as necessary */
}

.minis-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mini-countdown-timer {
    display: flex;
    justify-content: center;
    height: 300px;
}

.timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    color: #aaa;
}

.mini-timer-value {
    font-size: 40px;
    color: white;
    font-family: 'RF Dewi', sans-serif;
}