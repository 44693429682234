.fit-hero-game {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 200px;
    /* Adjust height as needed */
    position: relative;
}

/* .hero,
.villain {
    cursor: pointer;
    position: absolute;
}

.hero {
    left: 10%;

}

.villain {
    right: 10%;

} */

.hero-attack-animation {
    animation: heroAttack 2s ease forwards;
}

.villain-attack-animation {
    animation: villainAttack 2s ease backwards;
}

@keyframes heroAttack {

    0%,
    20%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(400px);
        /* Hero moves towards the villain */
    }

    60% {
        transform: translateX(400px);
        /* Hero stays at collision point */
    }

    80% {
        transform: translateX(0);
        /* Hero returns to start position */
    }
}

@keyframes villainAttack {

    0%,
    20%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(-400px);
        /* Villain moves towards the hero */
    }

    60% {
        transform: translateX(-400px);
        /* Villain stays at collision point */
    }

    80% {
        transform: translateX(0);
        /* Villain returns to start position */
    }
}

.image {
    width: 100px;
    border-radius: 50px;
}

.pow-image {
    width: 150px;
}

/* Ensure the z-index is higher for the character that is attacking */
.hero-attack-animation {
    z-index: 1;
}

.villain-attack-animation {
    z-index: 0;
}

.collision-effect {
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.shake-animation {
    animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}