.attribute-container {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 20px;
}



.villain-prediction-div {
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: auto;
	align-items: center;
	text-align: center;
	font-size: 20px;
}

.villian-header-2 {
	font-weight: 200;
}

.villian-scaling-spinner {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.finding-challenge-progress-bar {
	height: 10px;
	margin-bottom: 20px;
	border-radius: 20px;
}

.centered-value {
	text-align: center;
}

.progress-bar-sub-container {
	width: 80%;
	margin: auto;
	text-align: center;

}

.progress-bar-display-name {
	text-align: center;
	font-size: 28px;
	text-align: center;
	color: white;

}

.level-hearts {
	font-size: 10px;
}

.card-title {
	font-size: 15px
}


.circular-progress-col {
	text-align: center;
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	/* align-items: center; */
	justify-content: center;
}

.circular-progress-col-villain {
	text-align: center;
	cursor: pointer;
}

.progress-bar-container {
	display: flex;
	color: white;
	/* margin-top: 30px; */
}

.progress-bar-container-athlete {
	color: white;
}

.attribute-title {
	font-size: 12px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.strength-circular-bar {}

.stat-icon-profile {
	font-size: 22px;
	border-radius: 50px;
	width: 80%;
	margin-left: 10%;
	padding: 10px;
}