.update-header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

.update-body {
    margin-top: 10px;
    margin-left: 10px;
}