.goal-table-row {
    cursor: pointer;
    text-align: center;
}

.goal-progress-bar {
    /* width: 80%; */
    /* margin: 10px; */
}

.goal-cell {
    padding: 0;
    text-align: left;
    margin-left: 10px;
    color: black;
    font-size: 14px;
}

.goal-almost-accomplished {
    display: flex;
    flex-wrap: nowrap;
}

.goal-bar-container {
    display: flex;
    align-items: center;
    /* margin: 20px; */
}

.goal-accomp-1 {
    color: white;
}

.goal-background {
    --background: white
}

.goal-background-past {
    --background: black;
}

.goal-progress-bar-container {
    width: 100%;
}

.horizontal-slider {
    width: 100%;
    margin: 10px;
}

.goal-slider {
    --bar-background: #e0e0de;
    --bar-height: 20px;
    --bar-border-radius: 50px;
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

/* ion-range::part(pin) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    transform: scale(1.01);
    top: 12px;
    min-width: 28px;
    height: 28px;
    transition: transform 120ms ease, background 120ms ease;
    z-index: 100;
    left: -28px;
    font-weight: bold;
    font-size: 14px;
} */

.goal-set-button {
    height: 32px;
    --background: green;
    color: white;
    --border-radius: 5px;
    width: 60px;
}

.goal-bonus-div {
    text-align: left;
    color: var(--ion-color-secondary);
    font-size: 15px;
    margin-left: 20px;
    margin-bottom: 10px;
}