.help-needed-card {
    background: #f9f9f9;
    color: #333;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.help-needed-card IonCardSubtitle {
    color: #ff9500;
}

.help-needed-card IonCardTitle {
    color: #ff3b30;
}

.help-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.help-bubble {
    position: relative;
    /* top: -20%;
    left: 100%;
    transform: translateX(-30%) translateY(-125%); */
    /* Center bubble */
    width: 90%;
    /* Adjust as necessary */
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    /* More styling for chat bubble */
    color: black
}

/* Add a pseudo-element for the chat tail */
.help-bubble::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 10%;
    /* transform: translateX(-50%); */
    border-style: solid;
    border-width: 0 80px 25px 50px;
    border-color: transparent transparent transparent white;
}

.mini-help-bubble {
    position: relative;
    width: 90%;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    color: black;
    z-index: 1000;
}

.mini-help-bubble::after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: -13%;
    border-style: solid;
    border-width: 20px 0px 25px 45px;
    border-color: transparent transparent white transparent;
    z-index: -100;
}