.toolbar-hero-chat {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.toolbar-hero-chat-name {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}

.hero-chat-avatar {
    height: 50px;
    width: 50px;
    margin-left: 55px;
}