.error-message {
    color: red;
    font-style: italic;
    font-size: 0.75em;
}

.notification-message {
    font-style: italic;
    font-size: 0.85em;
}

.login-title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 16px;
}

.login-label {
    margin-bottom: 0px !important;
    height: auto !important;
}

.login-main-div {
    margin-top: 30%;
    position: relative;
    min-height: 50%;
    height: 75% !important;
    padding-bottom: 5em;
}

.login-logo {
    padding-top: 20%;
    width: 70%;
    margin: auto;
}

.login-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.forgot-password {
    text-align: right;
    padding-top: 16px;
    padding-right: 16px;
}