ion-modal#activity-notifications-modal {
    /* --width: fit-content; */
    --min-width: 250px;
    /* --height: 80%; */
    --height: fit-content;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 90%;
}

ion-modal#no-current-duel-notifications-modal {
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 20px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: 90%;
}


.celebration-top-area {
    /* height: 300px; */
    text-align: center;
    margin-bottom: 10px;
    /* margin-left: 15px; */
    /* margin-right: 15px; */
    margin-top: 15px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

}

.celebration-next-villains {
    display: flex;
    justify-content: center;
}

.celebration-middle-header {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}

.duel-points-display {
    background: var(--ion-color-primary);
    padding: 15px;
    border-radius: 250px;
}

.celebration-middle-area {
    text-align: center;
    width: 80%;
    margin: auto;
}

.celebration-bottom-area {
    text-align: center;
}

.celebration-subtitle {
    font-size: 18px;
    margin-bottom: 24px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background: black;
    color: white;
    border-radius: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.celebration-stat-icon {
    background: #606060;
    width: fit-content;
    border-radius: 20px;
    height: fit-content;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px
}

.celebration-stat-details {
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.celebration-stat-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.celebration-goal-item {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    font-size: 20px;
}

.celebration-stat-current {
    color: white;
    display: flex;
    align-items: center;
}

.celebration-stat-change {
    color: var(--cardio-color);

}

.celebration-stat-arrow {
    padding-left: 5px;
    padding-right: 5px;
}

.celebration-level-up-div {
    font-size: 50px;
    text-align: center;
    background: black;
    width: 100px;
    color: white;
    margin: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 85px;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    left: -20px;
}

.celebration-body-text {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    /* margin-left: 20%;
    margin-right: 20%; */
}

.celebration-skill-description {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
}

.celebration-stat-icon-strength {
    background: var(--strength-color);
}

.celebration-stat-icon-cardio {
    background: var(--cardio-color);
}

.celebration-stat-icon-mobility {
    background: var(--mobility-color);
}

.celebration-stat-icon-mindfulness {
    background: var(--mindfulness-color);
}

.celebration-swiper .swiper-button-next,
.celebration-swiper .swiper-button-prev {
    visibility: visible;
}

.celebrations-attributes-container {
    width: 70%;
    margin: auto;
}

.celebration-container {
    margin: 15px;
    height: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
}

.celebration-slide {
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.celebration-level-text {
    position: absolute;
    left: 50%;
    top: 46%;
    font-size: 30px;
    text-shadow: 2px 0 4px rgb(0 0 0);
    font-weight: bold;
    font-family: 'RF DEWI';
    transform: translate(-50%, -50%);
    /* Center the text on the image */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    1% {
        visibility: visible;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.fade-in {
    animation: fadeIn 1s forwards;
}

.celebration-level-container {
    position: relative;
    /* width: 200px; */
    /* Set to the width of your image */
    /* height: 200px; */
    /* Set to the height of your image */
}

.image-container {
    position: relative;
    overflow: hidden;
}

.hero-avatar-image-container {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.hero-avatar-image-container-tour {
    position: relative;
    display: flex;
    justify-content: center;
}

.smaller-image-container {
    position: relative;
    width: 100px;
    /* Set to the width of your image */
    height: 100px;
    /* Set to the height of your image */
    overflow: hidden;
    /* This ensures that anything outside this container does not show */
}

.defeated-image-container {
    display: flex;
}

.boss-image {
    /* width: 100%;
    height: 100%; */
    transition: filter 2s ease-in-out;
    /* Adjust time as needed */
}

.x-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 3s ease-in-out;
    /* Adjust time as needed */
    transition-delay: 15s;
    /* Delay the start until after the grayscale filter is applied */
    z-index: 10;
    /* Ensures the 'X' is over the boss image */
}

/* Start the grayscale filter after a slight delay to notice the image first */
@keyframes applyGrayScale {
    to {
        filter: grayscale(100%);
    }
}

/* Zoom in the 'X' */
@keyframes zoomInX {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(.3);
    }

    100% {
        transform: translate(-50%, -50%) scale(.6);
    }
}

/* Adding the 'defeated' class in JavaScript will start the animations */
.defeated .boss-image {
    animation: applyGrayScale 1s forwards;
}

.defeated .x-image {
    animation-name: zoomInX;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.villain-chat-container {
    position: relative;
    display: block;
    /* align-items: end;
    justify-content: center; */
}

.villain-image {
    width: 150px;
    /* or the size you want */
    height: auto;
    display: block;
}

.chat-bubble {
    position: relative;
    /* top: -20%;
    left: 100%;
    transform: translateX(-30%) translateY(-125%); */
    /* Center bubble */
    width: 90%;
    /* Adjust as necessary */
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    /* More styling for chat bubble */
    color: black
}

/* Add a pseudo-element for the chat tail */
.chat-bubble::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 25%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 20px 30px 10px;
    border-color: transparent white transparent transparent;
}

.celebration-bubble {
    position: relative;
    /* top: -20%;
    left: 100%;
    transform: translateX(-30%) translateY(-125%); */
    /* Center bubble */
    width: 90%;
    /* Adjust as necessary */
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    /* More styling for chat bubble */
    color: black;
    min-height: 140px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.tour-bubble {
    position: relative;
    /* top: -20%;
    left: 100%;
    transform: translateX(-30%) translateY(-125%); */
    /* Center bubble */
    width: 90%;
    /* Adjust as necessary */
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #666;
    /* More styling for chat bubble */
    color: black;
    /* min-height: 140px; */
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.celebration-bubble::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: -6%;
    /* transform: translateX(-50%); */
    border-style: solid;
    border-width: 0px 53px 28px 27px;
    border-color: white white transparent transparent;
}

.tour-bubble::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: -6%;
    /* transform: translateX(-50%); */
    border-style: solid;
    border-width: 0px 53px 28px 27px;
    border-color: white white transparent transparent;
}