.paywall-top-image-container {
    height: 175px;
    position: relative;
}

div.paywall-top-image {
    height: 100px;
}

img.paywall-top-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

div.paywall-avatar {
    /* position: relative;
    /* left: 40%;
    top: -70px; */
    position: absolute;
    left: 40%;
    top: 50px;
}

img.paywall-avatar {
    object-fit: cover;
    margin: auto;
    border-radius: 250px;
    /* box-shadow: 2px 0px 22px white;
    border: 3px solid rgb(255, 255, 255, 0.3) */
}

.paywall-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
}

.paywall-header-text {
    text-align: center;
    font-size: 25px;
    width: 80%;
    margin: auto;
    font-family: 'RF Dewi';
    /* margin-top: -40px; */
    margin-bottom: 20px;
}

.paywall-features {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.paywall-hero-name {
    font-size: 30px;
    font-family: 'RF Dewi';
    font-weight: bold;
}

.paywall-feature-row {
    margin-bottom: 5px;
}

.paywall-feature-bullet {
    font-size: 20px;
    font-family: 'RF Dewi';
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px 17px 5px 17px;
}

.paywall-feature-bullet-div {
    background: var(--mobility-color);
    border-radius: 50px;
    padding: 5px;
}

.paywall-feature-text {
    font-size: 18px;
    font-family: 'RF Dewi';
    margin-left: 10px;
}

.paywall-price-points {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.paywall-radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.paywall-price-point-price {
    text-align: right;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
}

.paywall-price-point {
    display: grid;
    width: 150px;
    align-items: center;
    border: 2px solid lightgray;
    border-radius: 20px;
    margin: 10px;
    /* padding: 20px; */
    position: relative;
    height: 125px;
    /* max-width: 30%; */
}

.paywall-price-point.selected {
    border: 2px solid var(--ion-color-tertiary);
}

.paywall-popular {
    position: relative;
    top: 10px;
    left: 40px;
    border: 1px solid var(--mobility-color);
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--mobility-color);
    border-radius: 20px;
}

.paywall-price-point-savings {
    position: absolute;
    top: 110px;
    left: 10px;
    border: 1px solid var(--mobility-color);
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--mobility-color);
    border-radius: 20px;
    font-size: 12px;
}

.paywall-price-point-savings-trial {
    position: absolute;
    top: 110px;
    left: 10px;
    border: 1px solid var(--mobility-color);
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--mobility-color);
    border-radius: 20px;
}

div.paywall-purchase-button {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

ion-button.paywall-purchase-button {
    --border-radius: 40px;
}

.paywall-footer-text {
    text-align: center;
    margin: 0 10% 10% 10%;
    font-size: 12px;
}

.paywall-trial-info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.paywall-crossed-out-price {
    color: lightgray;
    text-decoration: line-through;
    padding-right: 10px;
    font-weight: lighter;
}

.paywall-price-point-title {
    font-size: 16px;
    font-family: 'RF Dewi';
    font-weight: bold;
}

.paywall-modal {}

.paywall-price-point-button {
    justify-content: center;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.paywall-price-point-inner {
    display: grid;
    text-align: center;
    position: relative;
}

.paywall-price-point-inner-selected {
    position: absolute;
    left: 80%;
    top: -30px
}

.paywall-price-point-inner-month-number {
    font-size: 18px;
}

.paywall-price-point-inner-month-text {
    font-weight: bold;

}

.paywall-blurred-text {
    filter: blur(5px);
}

.paywall-powerup-button {
    height: 30px;
}

.paywall-villain-weaks-div {
    position: absolute;
    top: -10px;
    margin: auto;
    width: 100%;
    text-align: center;
}

.paywall-celebration-modal {
    --height: 70%;
    --width: 70%;
    --backdrop-opacity: .7 !important
}

.paywall-celebration {
    width: 80%;
    margin: auto;
    text-align: center;
}

.paywall-celebration-text {
    margin-top: 70px;
}

.paywall-celebration-button {
    margin-top: 20px
}