.member-image-feed {
    height: 50px;
    width: 50px;
}

.member-image-feed-mini {
    height: 25px;
    width: 25px;
}

.hero-row {
    background: #a9a9a947;
    align-items: center;
}

.hero-row-alt {
    align-items: center;
}

.leaderboard-column-header {
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
    font-variant-caps: small-caps;
    font-size: 14px;
}

.challenge-leaderboard-column-header {
    font-weight: bold;
    color: #fff;
    font-variant-caps: small-caps;
    font-size: 14px;
}

.hero-row-current {
    background: var(--ion-color-tertiary);
    align-items: center;
}

.date-separator {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    margin: 20px 0 10px;
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
}

.time-separator {
    display: block;
    font-size: 0.9em;
    color: #666;
    margin: 5px 0;
    padding: 0 10px;
}

.feed-item-wrapper {
    margin-bottom: 20px;
}