.finding-challenge-progress-bar {
    height: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.centered-value {
    text-align: center;
}

/* ion-item:nth-child(odd) {
    background-color: #f9f9f9;
} */

.ledger-day-break {
    margin: 15px 0px 15px 10px;
    color: white;
}

.activity-history-item {
    width: 80%;
    background: white;
    margin: auto;
    color: black;
    border: 1px solid grey;
    border-radius: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    transition: all 0.3s ease;
}

.activity-history-item-chat {
    background: lightgray;
    margin: auto;
    color: black;
    border: 1px solid grey;
    margin-bottom: -10px;
    padding-left: 5px;
}

.activity-history-item.expanded {
    border-radius: 20px;
}

.activity-history-item.expanded.strength {
    box-shadow: 0px 1px 20px 11px rgb(128 0 0);
    /* Add box shadow */
}

.activity-history-item.expanded.cardio {
    box-shadow: 0px 1px 20px 11px rgb(0 128 0);
    /* Add box shadow */
}

.activity-history-item.expanded.mobility {
    box-shadow: 0px 1px 20px 11px rgb(0 0 128);
    /* Add box shadow */
}

.activity-history-item.expanded.mindfulness {
    box-shadow: 0px 1px 20px 11px rgb(70 0 128);
    /* Add box shadow */
}


.expanded-section p {
    margin-bottom: 0px;
    /* Space out the elements */
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.expanded-section p:last-child {
    margin-bottom: 10px;
    /* Remove margin from last element */
}

.expanded-section {
    padding: 10px;
    font-size: 0.9em;
    /* Slightly smaller text */
    color: #999;
    /* Slightly lighter text color */
}

.activity-history-activityname {
    font-weight: bold;
    height: 18px;
}

.activity-history-activityname.smaller {
    font-size: 10px;
}

.activity-history-date {
    font-weight: bold;
}

.activity-history-duration {}

.activity-history-points.multi-point {
    font-size: 12px;
    padding-top: 3px;
}

.activity-history-icon-col {}

.activity-history-icon {

    border-radius: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 7px;
}

.member-image {
    height: 100px;
    width: 100px;
    margin-left: 15px;
}

.member-image-smaller {
    height: 75px;
    width: 75px;
    margin-left: 15px;
}

.member-image-smallest {
    height: 50px;
    width: 50px;
    margin-left: 15px;
}


.avatar-powerup-border {
    object-fit: cover;
    margin: auto;
    border-radius: 250px;
    box-shadow: 2px 0px 22px var(--ion-color-tertiary);
    border: 3px solid rgba(255, 140, 0, 0.3);
}

.avatar-powerup-badge {
    text-align: center;
    position: relative;
    top: -25px;
    background: var(--ion-color-tertiary);
    border-radius: 6px;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
}

.avatar-powerup-badge-icon {
    background: var(--ion-color-tertiary);
    border-radius: 6px;
    /* width: 20px; */
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    display: inline;
}

.avatar-powerup-badge.fadeIn {
    opacity: 0;
    /* Start invisible */
    animation: fadeIn 5s ease forwards;
    /* Apply fade-in animation */
}

.avatar-image-wrapper {
    position: relative;
    display: inline-block;
}

@keyframes fadeInNOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.avatar-image-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    margin: auto;
    border-radius: 250px;
    box-shadow: 0px 0px 50px 30px var(--ion-color-tertiary);
    border: 3px solid rgba(255, 140, 0, 0.7);
    opacity: 0;
    animation: fadeInNOut 3s linear infinite;
}

.avatar-image-wrapper img {
    display: block;
    width: 100%;
    /* Adjust as necessary */
    height: auto;
    border-radius: 250px;
}

.other-user-avatar {
    object-fit: cover;
    margin: auto;
    border-radius: 250px;
}


/* .activity-history-icon.cardio {
	background: rgb(0 128 0 / 14%);
}
.activity-history-icon.strength {
	background: rgba(128, 0, 0, 0.14);
}
.activity-history-icon.mobility {
	background: rgba(0, 0, 128, 0.14);
}
.activity-history-icon.mindfulness {
	background: rgba(70, 0, 128, 0.14);
} */

.profile-level-container {
    display: flex;
    text-align: center;
    margin: 10px;
    font-size: 14px;
}

.profile-level-bar-container {
    display: grid;
    align-items: center;
}

.secondary-box {
    margin: 5px;
    text-align: center;
}

.profile-background {
    --background: #47474b;
    background-color: none !important;
}