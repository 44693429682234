div.home-sun {
    position: absolute;
    left: 75%;
    top: -100px;
}

span.home-sun {
    height: 200px;
    width: 200px;
    background-color: rgb(231, 231, 0);
    border-radius: 50%;
    display: inline-block;
}