.app-centering {
    max-width: 800px;
    margin: auto;
    margin-left: min(33%)
}

.app-centering-onboarding {
    max-width: 800px;
    margin: auto;
}

.app-centering-login {
    max-width: 800px;
    margin: auto;
    margin-top: 10%;
}

.desktop-page {
    display: flex;
    flex-direction: row;
    margin-top: clamp(70px, 3%, 10%);
}

ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}


ion-content#dashboard {
    --offset-bottom: 0px !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.desktop-menu-list {
    background: none;
}

.desktop-menu-item {
    --background: none;
    background-color: initial !important;
}

.desktop-menu-item.active {
    --background: none;
    background-color: var(--ion-color-tertiary) !important;
}

.desktop-add-activity-modal {
    /* --width: 100%;
    --height: 600px;
    max-width: 1200px;
    margin: auto; */
    padding: 5%;
    height: 100%;
    --height: 100%;
}

.duration-inputs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: var(--ion-color-secondary) !important;
}

.duration-input {
    width: 50px;
    text-align: center;
    background: var(--ion-color-secondary) !important;
    padding-right: 10px !important;
}

.duration-label {
    margin-left: 5px;

}

/* For Chrome/Edge/Safari */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}