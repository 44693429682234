.stats-date-selector-div{
    text-align: center;
}

.stats-weekly-summary-div{
    text-align: center;
}

.stats-percentage-change {
    font-size: 10px;
}