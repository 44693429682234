ion-popover#badge::part(content)  {
  --background: linear-gradient(to right, #ff9966, #ff5e62);
  --color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  font-family: Arial, sans-serif;
}

.badge .badge-container {
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: white;
}

.badge .badge-container .star {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.badge .badge-container .star {
  position: absolute;
  width: 50px;
  height: 50px;
  animation: spinStar 1s linear infinite, moveStar 3s linear infinite;
}

@keyframes spinStar {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes moveStar {
  0% { transform: translate(0, 0); opacity: 0; }
  10% { opacity: 1; }
  100% { transform: translate(100vw, 100vh); opacity: 0; }
}